<template>
  <div id="google-map">
    <section>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2693.707970347911!2d19.08000421580575!3d47.534548200945395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741db95f0ba7c91%3A0x77a2b9abc612e54a!2zSE9ORyBLT05HIFJFU1RBVVJBTlQg6aaZ5riv6aSQ5Y6F!5e0!3m2!1sen!2shu!4v1667350814504!5m2!1sen!2shu" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
  </div>
</template>

<script>
export default {
  name: 'google-map',
  components: {
  }
}
</script>