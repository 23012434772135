export default {
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong Restaurant"])},
  "taiwan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan Restaurant"])},
  "menu": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "soups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soups"])},
    "appetizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appetizers"])},
    "dimSums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dim sums"])},
    "meats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meats"])},
    "seaFoods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sea foods"])},
    "vegetables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegetables"])},
    "mixedPlates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed plates"])},
    "garnishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garnishes"])},
    "desserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desserts"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traditional Chinese restaurant in Budapest."])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our restaurant was opened more than 31 years ago, in 1991 and it is one of the biggest Chinese restaurant in Hungary, We offer a wide variety of Chinese cuisines from all over China, which are made by professional Chinese chefs. The specialty of Chinese cuisine is that you can try and share the wide variety of food with your family and friends that it offers."])}
  },
  "gallery": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foods"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beijing roast duck (whole)"])},
    "93cut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beijing roast duck cut in pieces"])},
    "137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celery with cashew nuts"])},
    "145a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiery bacon with cauliflower"])},
    "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-Po style pork"])},
    "162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fried noodles with king prawns"])},
    "16b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prawn shashlik with garlic"])},
    "83a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gung-Bao Chicken"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermicelli with cucumber and jellzfish"])},
    "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qing tian pork"])},
    "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fillet of salmon on ice"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bamboo with soy sauce"])},
    "99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flatfish"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our restaurant is located at 1135 Budapest, Béke u. 26. We also have a private parking, which is free for guests."])},
    "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
    "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table reservation"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening hours"])},
    "kitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our kitchen is open until 21:30."])},
    "everyday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyday"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full address"])}
  },
  "admin": {
    "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Editor"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "item": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New item"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit item"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English name"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian name"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese name"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
    }
  }
}